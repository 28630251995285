<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-toolbar
          flat
          color="#018656"
          dark
        >
          <v-btn
            icon
            dark
            @click="$emit('close', false)"
          >
            <v-icon color="white" dense small>fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Rastreio - <span style="margin: 0px 25px;">Pedido: {{ consulta.item.numero_pedido }}</span> <span style="margin: 0px 25px;">Codigo de rastreio: {{ consulta.item.numero_rastreio }}</span> <span style="margin: 0px 25px;">Data prevista: {{ dataPrevista }}</span></v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mt-5">
          <Rastreio :nroobjeto="consulta.item.numero_rastreio" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('close', false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment'
import Rastreio from '../frmrastreio.vue'
  export default {
    data () {
      return {
        
      }
    },

    components: {
      Rastreio
    },

    props: {
      consulta: Object,
      dialog: Boolean
    },

    computed: {
      dataPrevista() {
        return moment(this.consulta.item.data_prevista).format("DD/MM/YYYY")
      }
    }
  }
</script>
<template>
  <div>
    <!-- Início filtro -->
		<Filtro v-if="modal.filtro" v-on:filtro="carregarFiltro" v-on:filtroFechar="fecharFiltro" :filtro="modal" />
    <!-- Fim filtro -->

  	<!-- Início detalhes -->
		<Detalhes v-if="detmodal" v-on:detclose="fecharDetalhes" :consulta="objeto" :encerrar="encerrar" />
  	<!-- Fim detalhes -->

     <!-- Inicio img consulta -->
     <v-dialog v-model="img.imgconsulta" class="text-center" persistent width="700">
      <v-card>
        <v-card-text style="padding-top: 17px">
          <v-row>
            Imagem do objeto
          </v-row>
          <v-row>
            <v-img :src="this.img.svgImg"></v-img>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" dark text @click="img.imgconsulta = false">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim img consulta -->

    <!-- Início principal -->
    <v-toolbar flat>
      <div class="d-flex mr-4">
        <v-icon> fas fa-boxes </v-icon>
        <v-toolbar-title class="pl-4"> Listagem de Objetos </v-toolbar-title>
      </div>

      <v-toolbar-items class="d-flex align-center">

        <!-- Data Início -->
        <v-text-field color="#018656" dense outlined label="Data inicial" prepend-icon="fas fa-calendar"
          :value="formatarData(dataInicio)" @click="exibirData = true" readonly hide-details width="fit-content">
        </v-text-field>

        <!-- Data Fim -->
        <v-text-field color="#018656" dense outlined label="Data final" :value="formatarData(dataFinal)"
          @click="exibirData = true" readonly hide-details class="ml-2" width="fit-content">
        </v-text-field>

        <!-- Calendário Datas -->
        <v-dialog v-model="exibirData" width="fit-content">
          <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
            <v-card-text class="mt-6">
              <v-card>
                <v-card-text style="padding-top: 10px;">
                  <v-row dense>
                    <v-col>
                      <h1
                        style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                        Data inicial
                      </h1>
                      <v-date-picker color="#018656" v-model="dataInicio" size="100" width="100%">
                      </v-date-picker>
                    </v-col>

                    <v-col>
                      <h1
                        style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                        Data final
                      </h1>
                      <v-date-picker color="#018656" v-model="dataFinal" size="100" width="100%">
                      </v-date-picker>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row style="margin-top: 10px;" dense justify="end">
                <v-col cols="12" sm="12">
                  <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                    @click="exibirData = false, limparFiltros(), carregarObjetos()">
                    CONFIRMAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-card-title class="pr-0">
          <v-text-field class="ml-1 mr-0" v-model="grid.objetos.pesquisa" color="#018656" prepend-icon="fas fa-search"
            label="Pesquisa rápida" single-line hide-details clearable sort-desc.sync="false" outlined dense>

            <template #append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon dense x-small v-on="on" color="primary">fas fa-question-circle</v-icon>
                </template>
                <span>Busca somente os objetos que aparecem na tela</span>
              </v-tooltip>
            </template>

          </v-text-field>
        </v-card-title>

      </v-toolbar-items>

      <v-spacer></v-spacer>

      <!-- Filtro -->
      <v-btn class="mr-2" type="button" color="#FF5252" style="color: #FFFFFF; width: 160px; height: 40px;"
        @click="() => { limparFiltros(), carregarObjetos() }">
        <v-icon dense class="mr-2">fas fa-broom</v-icon>
        LIMPAR
      </v-btn>

      <v-btn class="mr-2" type="button" color="#018656" style="color: #FFFFFF; width: 160px; height: 40px;"
        @click="() => modal.filtro = true">
        <v-icon dense class="mx-2">fas fa-filter</v-icon>
        FILTRAR
      </v-btn>

      <v-app-bar-nav-icon @click="fechar">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <!-- Inicio Tabela -->
    <v-card outlined>
      <v-card-text class="pb-2">
        <v-row class="overflow-y-auto">
          <v-col class="px-1 py-0">
            <v-data-table height="calc(100vh - 229px)" style="overflow-y: auto; position: relative;" color="#018656" dense :headers="grid.objetos.cabecalho" :items="grid.objetos.items"
              :items-per-page="grid.objetos.porPagina" hide-default-footer class="pt-1" :search="grid.objetos.pesquisa"
              :loading="grid.objetos.carregando" item-key="codigo" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
              @update:sort-by="sortTable" @update:sort-desc="sortTable" fixed-header>

              <template #progress>
                <v-progress-linear absolute style="width: 100%;" height="3" color="#018656" indeterminate></v-progress-linear>
              </template>

              <template #header.data_venda="{ header }">
                <div style="white-space: nowrap;">
                  {{ header.text }}
                </div>
              </template>

              <template #item.numero_pedido="{ item }">
                <a @click="carregarDetalhes(item)" style="text-decoration: none; color: #018656; font-weight: bold;" >
                  {{ item.numero_pedido }}
                </a>
              </template>

              <template #item.time_line="{ item }">
                <span v-if="item.time_line == 0">Aguardando Início de transporte</span>
                <span v-if="item.time_line == 1">Postado</span>
                <span v-if="item.time_line == 2">Em Transporte</span>
                <span v-if="item.time_line == 3">Saiu para Entrega</span>
                <span v-if="item.time_line == 4">Entregue</span>
                <span v-if="item.time_line == 5">Cancelado/Finalizado</span>
              </template>

              <template #item.ultima_posicao="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <td v-on="on" :style="width">
                      {{ item.ultima_posicao }}
                    </td>
                  </template>
                  {{ item.ultima_posicao }}
                </v-tooltip>
              </template>

              <!-- This template looks for headers with formatters and executes them -->
              <template v-for="header in grid.objetos.cabecalho.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
              >
                {{ header.formatter(value) }}
              </template>

              <template #item.acoes="{ item }">
                <div class="d-flex justify-center align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" icon v-on="on" small dense @click="openDatasDialog(item)">
                        <v-icon color="#018656" dense small>fas fa-calendar</v-icon>
                      </v-btn>
                    </template>
                    <span>Datas</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" @click="urlConsulta(item)" icon dense v-on="on">
                        <v-icon dense small color="#018656">fas fa-globe-americas</v-icon>
                      </v-btn>
                    </template>
                    <span>Copiar Link Rastreio</span>
                  </v-tooltip>

                  <!-- <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" icon dense v-on="on">
                        <v-icon dense small color="#018656">fas fa-box-open</v-icon>
                      </v-btn>
                    </template>
                    <span>Gerar Etiqueta</span>
                  </v-tooltip> -->

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" icon v-on="on" small dense :disabled="item.alerta != 1">
                        <v-icon :disabled="item.alerta != 1" @click="openAlertasDialog(item)" dense small :color="item.alerta == 1 ? 'orange' : ''">fas fa-exclamation-triangle</v-icon>
                      </v-btn>
                    </template>
                    <span>Alertas</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" icon dense v-on="on" @click="openRastreioDialog(item)">
                        <v-icon dense small color="#018656">fas fa-map-marked-alt</v-icon>
                      </v-btn>
                    </template>
                    <span>Rastreio</span>
                  </v-tooltip>                  

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" icon dense v-on="on" @click="openNotificacoesDialog(item)">
                        <v-icon dense small :color="getColorNotify(item)">fas fa-bell</v-icon>
                      </v-btn>
                    </template>
                    <span>Notificações</span>
                  </v-tooltip>

                  <!-- <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon dense v-on="on" style="width: 26px !important;">
                        <v-icon dense small color="#018656">fas fa-dollar-sign</v-icon>
                      </v-btn>
                    </template>
                    <span>Auditoria</span>
                  </v-tooltip> -->

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn style="width: 30px !important;" @click="imgConsulta(item)" :disabled="item.transportadora.toUpperCase() != 'CORREIOS'" icon dense v-on="on">
                        <v-icon dense small :color="item.transportadora.toUpperCase() == 'CORREIOS' ? '#018656' : ''" :disabled="item.transportadora.toUpperCase() != 'CORREIOS'">
                          fas fa-file-alt
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Comprovante de Entrega</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-snackbar
          v-model="snackbar"
          color="success"
          centered
          timeout="2000"
        >
          <b>Link copiado para a área de transferência!</b>

          <template v-slot:action="{ attrs }">
            <v-btn
              small
              color="white"
              icon
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon small dense>fas fa-check</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-row align="end" class="mx-2" style="margin-top: 15px; position: relative;">
          <v-col cols="8" offset="2">
            <v-pagination color="#018656" v-model="grid.objetos.paginaAtual"
              :length=" Math.ceil( grid.objetos.totalRegistros / this.grid.objetos.porPagina ) "
              @input="mudarPagina" :total-visible="5"
            ></v-pagination>
          </v-col>
          <v-col cols="2">
            <v-select dense outlined label="Resultados por página" :items="grid.objetos.qtdsPaginacao"
              item-text="text" item-value="value" v-model="grid.objetos.porPagina" color="#018656" hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <dialog-datas :dialog="dialogs.datas.visible" :datas="dialogs.datas.data" @close="dialogs.datas.visible = $event"></dialog-datas>
    <dialog-notificacoes :dialog="dialogs.notificacoes.visible" :consulta="objeto" @close="dialogs.notificacoes.visible = $event"></dialog-notificacoes>
    <dialog-rastreio :dialog="dialogs.rastreio.visible" :consulta="objeto" @close="dialogs.rastreio.visible = $event"></dialog-rastreio>
    <dialog-alertas :dialog="dialogs.alertas.visible" :consulta="objeto" @close="dialogs.alertas.visible = $event"></dialog-alertas>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";
import Filtro from "../components/objetos/frmfiltro.vue"
import Detalhes from "../components/objetos/frmdetalhes.vue";
import DialogDatas from '../components/objetos/dialogs/datas.vue'
import DialogNotificacoes from '../components/objetos/dialogs/notificacoes.vue'
import DialogRastreio from '../components/objetos/dialogs/rastreio.vue'
import DialogAlertas from '../components/objetos/dialogs/alertas.vue'
export default {
  components: {
    Template,
    Filtro,
		Detalhes,
    DialogDatas,
    DialogNotificacoes,
    DialogRastreio,
    DialogAlertas
  },
  name: "Objetos",
  data: () => ({
    img: { imgconsulta: false, svgImg: '', },
    codigo_empresa: '',
    dataInicio: moment( new Date(new Date().getTime()).setDate(new Date().getDate() - 30) ).format("YYYY-MM-DD"),
    dataFinal: moment(new Date()).format("YYYY-MM-DD"),
    snackbar: false,
    dialogs: {
      datas: {
        visible: false,
        data: []
      },
      notificacoes: {
        visible: false
      },
      rastreio: {
        visible: false
      },
      alertas: {
        visible: false
      }
    },
    sortBy: [],
    sortDesc: false,
    listDesc: [],
    exibirData: false,
    movimentacoes_erro: ["Extravio Total", "Extravio Parcial", "Solicitação de suspensão de entrega recebida", "Extravio de Mercadoria em Trânsito",
      "Falta com Busca/Reconferência", "Roubo de Carga", "Objeto roubado dos Correios"],

    grid: {
      objetos: {
        cabecalho: [
          { text: "Data do pedido", value: "data_venda", formatter: (x) => x ? moment(x).format("DD/MM/yyyy") : null, sortable: false },
          { text: "Número Pedido", value: "numero_pedido", sortable: false },
          { text: "Pedido Canal Venda", value: "pedido_canal_venda", sortable: false },
          { text: "Transportadora", value: "transportadora", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Timeline", value: "time_line", sortable: false },
          { text: "Movimentação", value: "ultima_posicao", sortable: false },
          { text: "Data prevista", value: "data_prevista",  sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy") : null },
          { text: "Ações", value: "acoes", sortable: false, align: 'center' },
        ],
        items: [],
        carregando: false,
        pesquisa: '',
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 25, 50, 100],
        porPagina: 50,
        coluna: "objetos.data_venda",
        direcao: "asc",
        ordenacao: {
          colunas: [
            { text: "Código", value: "objetos.codigo" },
            { text: "Transportadora", value: "transportadoras.transportadora" },
            { text: "Rastreio", value: "objetos.numero_rastreio" },
            { text: "Prazo", value: "objetos.prazo_entrega" },
            { text: "Dt. Cons.", value: "objetos.data_considerada" },
            { text: "Dt. Prev", value: "objetos.data_prevista" },
            { text: "Dt. Ent.", value: "objetos.data_entrega" },
            { text: "Cep Origem", value: "objetos.remetente_cep" },
            { text: "Cep Destino", value: "objetos.destinatario_cep" },
            { text: "Dt. Mov.", value: "objetos.data_ultima_posicao" },
            { text: "Mov.", value: "objetos.ultima_posicao" },
          ],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem/Destino", value: "origem_cidade_uf_local", sortable: false },
          { text: "Retirada", value: "endereco_retirada", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
    encerrar: false,
    modal: {
      filtro: false,
      name: "Objetos",
      cod_transportadora: true,
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      pi: false,
      sendComplaint: false,
      data_inicio: false,
      data_final: false,
      cod_servico: true,
    },
    filtros: {
      codigo: "",
      cod_transportadora: null,
      numero_rastreio: "",
      dt_considerada_inicio: "",
      dt_considerada_final: "",
      dt_considerada_null: false,
      dt_prevista_inicio: "",
      dt_prevista_final: "",
      dt_prevista_null: false,
      dt_entrega_inicio: "",
      dt_entrega_final: "",
      dt_entrega_null: false,
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao_inicio: "",
      dt_ultima_posicao_fim: "",
      ultima_posicao: "",
      data_inicio: null,
      data_final: null,
      cod_servico: "",
    },
    transportadoras: [],
    itensReclamacao: [],
    detalhes: [],
    objetoAtual: "",
    habilita: false,
    filtro: false,
    filtroFechar: false,
    detalhes: {
      enviado: {
        data_postagem: "",
        data_considerada: "",
        cep: "",
        cidade: "",
      },
      para: {
        nome: "",
        cep: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      previsao_de_entrega: {
        data: "",
        em_domicilio: "",
      },
      entregue_em: {
        data: "",
      },
      cod_servico: '',
      tipo_objeto: '',
      numero_rastreio: "",
      numero_danfe: "",
      order_id: "",
    },
    objeto: {
      numero_rastreio: "",
      rastreio: "",
      protocolo_reclamacao: "",
      item: {},
      detalhes: false,
      habilita: false,
      aba_rastreio: true,
    },
    ocor: {
      cabecalho: [
        { text: "Status", value: "status", sortable: false },
        { text: "Cod. Situação", value: "cod_situacao", sortable: false },
        { text: "Devovulção", value: "devolvido", sortable: false },
        { text: "Tentativa Entrega", value: "tentativa_entrega", sortable: false },
        { text: "Obj. Não Encontrado", value: "objeto_nao_encontrado", sortable: false },
        { text: "Extravio", value: "extravio", sortable: false },
        { text: "Roubo", value: "roubo", sortable: false },
        { text: "Falha Remetente", value: "falha_remetente", sortable: false },
        { text: "Falha Fiscal", value: "falha_fiscal", sortable: false },
        { text: "Atraso", value: "atraso", sortable: false },
        { text: "Aguardando Retirada", value: "aguardando_retirada", sortable: false },
        { text: "Avaria", value: "avaria", sortable: false },
        { text: "Alerta", value: "alerta", sortable: false },
        { text: "Tratativa Pendente", value: "tratativa_pendente", sortable: false },
      ],
      items: [],
      carregando: false,
      paginaAtual: 1,
    },
    detmodal: false,
    numero_rastreio: null,
    url_token: '',
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    this.limparFiltros();
    await this.carregarObjetos();
    await this.carregarTransportadoras();
    await this.carregarMotivosReclamacao();
  },
  beforeMount() {
    this.filtroFechar = this.modal;
  },
  methods: {
    async imgConsulta(objeto) {
      console.log("Acessado img");
      let url = `${baseApiUrl}/objeto-img`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${objeto.numero_rastreio}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.img.svgImg = `${response.data.dados}`;
            this.img.imgconsulta = true;
          } else {
            this.$swal({
              title: 'Que pena!!!',
              text: `${response.data.message}`,
              icon: 'warning',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#4BB543',
              allowOutsideClick: true,
            });

          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },

    getColorNotify(objeto) {
      if(!objeto.data_considerada || objeto.data_prevista) {
        let data_hoje = new Date();
        let data_prevista = new Date(new Date(objeto.data_prevista).getUTCFullYear(), new Date(objeto.data_prevista).getUTCMonth(),
        new Date(objeto.data_prevista).getUTCDate());
        
        if(!objeto.data_considerada || data_prevista > data_hoje && !objeto.data_entrega) {
          return '#FF0000';
        } else {
          return '#018656'
        }
      } else {
        return '#018656'
      }
    },

    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.pedido_canal_venda = "";
      this.filtros.cod_transportadora = null;
      this.filtros.canal_venda = "";
      this.filtros.sub_canal = "";
      this.filtros.numero_rastreio = "";
      this.filtros.dt_considerada_inicio = "";
      this.filtros.dt_considerada_final = "";
      this.filtros.dt_considerada_null = false;
      this.filtros.dt_prevista_inicio = "";
      this.filtros.dt_prevista_final = "";
      this.filtros.dt_prevista_null = false;
      this.filtros.dt_entrega_inicio = "";
      this.filtros.dt_entrega_final = "";
      this.filtros.dt_entrega_null = false;
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao_inicio = "";
      this.filtros.dt_ultima_posicao_fim = "";
      this.filtros.ultima_posicao = "";
      this.filtros.id_canal_vendas = "";
      this.filtros.cod_servico = "";

      this.grid.objetos.pesquisa = '';
      this.grid.objetos.porPagina = 50;
      this.grid.objetos.paginaAtual = 1;
    },

    openDatasDialog (item) {
      this.dialogs.datas.visible = true
      let arr = []
      arr.push(item)
      this.dialogs.datas.data = arr
    },

    openAlertasDialog (item) {
      this.objeto.item = item;
      if(Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30 && item.data_entrega == null && item.data_considerada != null){
        this.encerrar = true;
      }
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.dialogs.alertas.visible = true
    },

    openRastreioDialog (item) {
      this.objeto.item = item;
      if(Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30 && item.data_entrega == null && item.data_considerada != null){
        this.encerrar = true;
      }
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.dialogs.rastreio.visible = true
    },

    openNotificacoesDialog (item) {
      this.objeto.item = item;
      if(Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30 && item.data_entrega == null && item.data_considerada != null){
        this.encerrar = true;
      }
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.dialogs.notificacoes.visible = true
    },

    fecharFiltro() {
      this.modal.filtro = false;
    },
    carregarFiltro( paramFiltro ) {
      this.modal.filtro = false;
      this.filtros = paramFiltro;
      this.carregarObjetos();
    },
    fechar() {
      this.$router.push("/");
    },
    async carregarObjetos() {
      this.grid.objetos.items = [];
      this.grid.objetos.carregando = true;
      let url = `${baseApiUrl}/objetos?page=${this.grid.objetos.paginaAtual}`;
      url += `&fim=${moment(this.dataFinal).format("YYYY-MM-DD")}&inicio=${moment(this.dataInicio).format("YYYY-MM-DD")}`;
      url += `&indicadores=true`;
      url += `&coluna=${this.grid.objetos.coluna}`;
      url += `&direcao=${this.grid.objetos.direcao}`;

      if (this.filtros.codigo != ""){
        url += `&codigo=${this.filtros.codigo}`;
        this.grid.objetos.paginaAtual = 1;
      }

      if(this.filtros.pedido_canal_venda != "" && this.filtros.pedido_canal_venda != null) {
        url += `&pedido_canal_venda=${this.filtros.pedido_canal_venda}`;
        this.grid.objetos.paginaAtual = 1;
      }

      if (this.filtros.cod_transportadora != null){
        url += `&cod_transportadora=${this.filtros.cod_transportadora}`;
      }

      if(this.filtros.canal_venda != ""){
        url += `&canal_venda=${this.filtros.canal_venda}`;
      }

      if(this.filtros.sub_canal != "" && this.filtros.sub_canal != null){
        url += `&sub_canal=${this.filtros.sub_canal}`
      }

      if (this.filtros.numero_rastreio != ""){
        url += `&numero_rastreio=${this.filtros.numero_rastreio}`;
        this.grid.objetos.paginaAtual = 1;
      }

      if (this.filtros.dt_considerada_inicio != "" && this.filtros.dt_considerada_final != ""
      && this.filtros.dt_considerada_inicio != null && this.filtros.dt_considerada_final != null){
        url += `&data_considerada_inicio=${this.filtros.dt_considerada_inicio}&data_considerada_fim=${this.filtros.dt_considerada_final}`;
      } else if(this.filtros.dt_considerada_null == true){
        url += `&sem_data_considerada=${this.filtros.dt_considerada_null}`;
      }

      if (this.filtros.dt_prevista_inicio != "" && this.filtros.dt_prevista_final != ""
      && this.filtros.dt_prevista_inicio != null && this.filtros.dt_prevista_final != null){
        url += `&data_prevista_inicio=${this.filtros.dt_prevista_inicio}&data_prevista_fim=${this.filtros.dt_prevista_final}`;
      } else if(this.filtros.dt_prevista_null == true){
        url += `&sem_data_prevista=${this.filtros.dt_prevista_null}`;
      }

      if (this.filtros.dt_entrega_inicio != "" && this.filtros.dt_entrega_final != ""
      && this.filtros.dt_entrega_inicio != null && this.filtros.dt_entrega_final != null){
        url += `&data_entrega_inicio=${this.filtros.dt_entrega_inicio}&data_entrega_fim=${this.filtros.dt_entrega_final}`;
      } else if(this.filtros.dt_entrega_null == true){
        url += `&sem_data_entrega=${this.filtros.dt_entrega_null}`;
      }

      if (this.filtros.cep_origem != ""){
        url += `&remetente_cep=${this.filtros.cep_origem}`;
      }
      if (this.filtros.cep_destino != ""){
        url += `&destinatario_cep=${this.filtros.cep_destino}`;
      }
      if (this.filtros.dt_ultima_posicao_inicio != "" && this.filtros.dt_ultima_posicao_fim != ""
      && this.filtros.dt_ultima_posicao_inicio != null && this.filtros.dt_ultima_posicao_fim != null
      ){
        url += `&data_ultima_posicao_inicio=${this.filtros.dt_ultima_posicao_inicio}
        &data_ultima_posicao_fim=${this.filtros.dt_ultima_posicao_fim}`;
      }
      if (this.filtros.ultima_posicao != ""){
        url += `&ultima_posicao=${this.filtros.ultima_posicao}`;
      }

      if(this.filtros.id_canal_vendas != "" && this.filtros.id_canal_vendas != null) {
        url += `&id_canal_vendas=${this.filtros.id_canal_vendas}`;
      }

      if (Number(this.grid.objetos.porPagina) > 0)
        url += `&por_pagina=${this.grid.objetos.porPagina}`;
      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;

      url += `&page=${this.grid.objetos.paginaAtual}`;

      await axios
        .get(url)
        .then((res) => {
          this.grid.objetos.items = res.data.dados.data;
          this.grid.objetos.totalRegistros = res.data.dados.total;
          this.grid.objetos.porPagina = Number(res.data.dados.per_page);
        })
        .finally(() => (this.grid.objetos.carregando = false));
    },
    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },
    async carregarTransportadoras() {
      let url = `${baseApiUrlPersys}/transportadora/empresa`;
      await axios.get(url, {
          headers: {
            'Token-API': this.$store.state.token_persys
          }
        }
      )
      .then((res) => {
        this.transportadoras = res.data;
      });
    },
    carregarMotivosReclamacao() {
      let url = `${baseApiUrl}/motivos-reclamacao`;
      axios.get(url).then((res) => {
        this.itensReclamacao = res.data.motivos;
      });
    },
    async carregarDetalhes(item) {
      this.objeto.item = item;
      if(Math.round((new Date().getTime() - new Date(item.data_prevista).getTime()) / (1000 * 3600 * 24)) > 30 && item.data_entrega == null && item.data_considerada != null){
        this.encerrar = true;
      }
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },

    async urlConsulta(objeto) {
      this.url_token = "";
      let url = `${baseApiUrl}/objeto-url`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${objeto.numero_rastreio}`;
      url += `&simpleBagq=Simple`;
      url += `&xspaceBagq=${objeto.codigo}`;
      this.numero_rastreio = objeto.numero_rastreio;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.url_token = `https://rastreieja.com.br/${response.data.dados.id}`;

            if(navigator.clipboard) {
              navigator.clipboard.writeText(this.url_token);
            } else {
              const textArea = document.createElement("textarea");
              textArea.value = this.url_token;
  
              textArea.style.left = "-999999px";
  
              document.body.prepend(textArea);
              textArea.select();
   
              try {
                document.execCommand('copy');
              } catch (error) {
                console.error(error);
              } finally {
                textArea.remove();
              }
            }
            this.snackbar = true
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },

    showShopee(item) {
      if(item.idcanal_vendas == 21 && item.ultima_posicao == null && item.data_considerada == null
      && item.data_prevista == null && item.data_entrega == null) {
        return true
      }
    },

    async encerrarShopee(objeto) {
      this.$swal({
        title: 'Confirmação',
        text: `Deseja realmente notificar o cancelamento à Shopee do objeto ${objeto.numero_pedido}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async(res) => {
        if(res.isConfirmed) {
          let url = `${baseApiUrl}/notifyChannel/${this.codigo_empresa}/${objeto.numero_rastreio}`;
          let data = moment(new Date()).format("YYYY-MM-DD");
          let data_hora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          
          await axios.get(url, {
            params: {
              info: [
                {
                  name: "Shopee",
                  info_line: {
                    status: "C",
                      cod_situacao: 0,
                      data_entrega: data,
                      data_ultima_posicao: data_hora,
                      ultima_posicao: "FINALIZACAO",
                      slug: "CANCELADO",
                      time_line: "5"
                    }
                }
              ]
            }
          }).then(() => {
            showSuccess("Notificação de cancelamento efetuada com sucesso!");
          }).catch(() => {
            showError("Erro na notificação do objeto");
          })
        }
      });
    },

    fecharDetalhes(Atualiza) {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
      }
      if (Atualiza){
        this.carregarObjetos();
      }
    },
    formatarData(data){
      return moment(data).format("DD/MM/YYYY");
    },

    sortTable(){
      if(this.sortBy[0] != null){
        this.grid.objetos.coluna = `objetos.${this.sortBy[0]}`;
        if(this.sortDesc == false || this.sortDesc[0] == false)
          this.grid.objetos.direcao = 'asc';
        else
          this.grid.objetos.direcao = 'desc';
      }else{
        this.grid.objetos.coluna = `objetos.data_venda`;
        this.grid.objetos.direcao = 'asc';
      }

      this.carregarObjetos();
    },

    async objetoEncerrar(objeto){
      let url = `${baseApiUrl}/objetos-encerrar?objeto=${objeto.codigo}`;
      let data = moment(new Date()).format("YYYY-MM-DD");

      this.$swal({
        title: 'Confirmação',
        text: `Tem certeza que deseja finalizar o objeto ${objeto.codigo}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async(res) => {
        if(res.isConfirmed) {
          await axios
            .patch(url, {
              "status": "F",
              "data_encerramento": data,
            })
              .then(() => {
                for(let i = 0; i < this.grid.objetos.items.length; i++){
                  if(this.grid.objetos.items[i].codigo == objeto.codigo){
                    this.grid.objetos.items.splice(i, 1);              
                  }
                }
              }).catch(() => {
                this.$swal({
                  title: 'Que pena!!!',
                  text: `Não foi possível finalizar o objeto ${objeto.codigo}.`,
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
              })
        }
      });
    },
  },
  watch: {
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 10;
            this.mudarPagina(1);
          }
        }
      },
    },
    "objeto.numero_rastreio": {
      handler: function (val) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.habilita = false;
          this.objeto.protocolo_reclamacao = "";
          this.objeto.numero_rastreio = "";
          this.objeto.rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.detalhes.numero_rastreio = "";
          this.detalhes.order_id = "";
          this.detalhes.numero_danfe = "";
          this.grid.detalhes.items = [];
        }
      },
    },
  },

  computed: {
    width() {
      switch(this.$vuetify.breakpoint.name) {
        case 'xs': return 'max-width: 10vw; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;'
        case 'sm': return 'max-width: 10vw; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;'
        case 'md': return 'max-width: 10vw; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;'
        case 'lg': return 'max-width: 18vw; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;'
        case 'xl': return 'white-space: nowrap; text-overflow: ellipsis; overflow: hidden;'
      }
    }
  }
};
</script>

<style>
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
</style>

<template>
  <v-row v-if="dialog" justify="center">
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-toolbar
          flat
          color="#018656"
          dark
        >
        <v-btn
            icon
            dark
            @click="$emit('close', false)"
          >
            <v-icon color="white" dense small>fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Notificações - Pedido: {{ consulta.item.numero_rastreio }}</v-toolbar-title>
        </v-toolbar>
      
        <v-card-text class="mt-5">
          <div class="d-flex align-center">
            <v-btn v-if="!consulta.item.data_considerada" class="ma-1 mb-3" color="primary" small @click="notificar(envio)">
              Notificar Envio
            </v-btn>
            <v-tooltip v-if="!consulta.item.data_considerada" class="fill-height" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small dense @click="openDatasDialog(item)">
                  <v-icon color="primary" dense small>fas fa-question-circle</v-icon>
                </v-btn>
              </template>
              <span>Notifica o canal de venda que o pedido foi embarcado independente do rastreio.</span>
            </v-tooltip>
            <v-btn v-if="novaDataPrevista > new Date() || consulta.item.data_entrega" class="ma-1 mb-3" color="success" small @click="notificar(entrega)">
              Notificar Entrega
            </v-btn>
            <v-tooltip v-if="novaDataPrevista > new Date() || consulta.item.data_entrega" class="fill-height" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small dense @click="openDatasDialog(item)">
                  <v-icon color="success" dense small>fas fa-question-circle</v-icon>
                </v-btn>
              </template>
              <span>Notifica o canal de venda que o pedido foi entregue independente do rastreio.</span>
            </v-tooltip>
            <v-btn v-if="!consulta.item.data_considerada || (novaDataPrevista > new Date() && !consulta.item.data_entrega)" class="ma-1 mb-3" color="error" small @click="notificar(cancelamento)">
              Cancelar Pedido
            </v-btn>
            <v-tooltip v-if="!consulta.item.data_considerada || (novaDataPrevista > new Date() && !consulta.item.data_entrega)" class="fill-height" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small dense @click="openDatasDialog(item)">
                  <v-icon color="error" dense small>fas fa-question-circle</v-icon>
                </v-btn>
              </template>
              <span>O cancelamento do pedido na plataforma vai notificar o canal de venda cadastrado e pode acarretar à devolução do valor da compra ao destinatário.</span>
            </v-tooltip>
          </div>
          <Notificacoes :nroobjeto="consulta.item.numero_rastreio" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('close', false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import Notificacoes from '../frmnotificacoes.vue'
import axios from 'axios'
  export default {
    data () {
      return {
        codigo_empresa: '',
        envio: {
          msg: 'o envio',
          status: 'A',
          cod_situacao: 2,
          ultima_posicao: 'EMISSAO',
          slug: 'Aguardando início do transporte',
          time_line: "1"
        },
        entrega: {
          msg: 'a entrega',
          status: 'D',
          cod_situacao: 3,
          ultima_posicao: 'ENTREGUE',
          slug: 'Entregue',
          time_line: "4"
        },
        cancelamento:{
          msg: 'o cancelamento',
          status: "C",
          cod_situacao: 0,
          ultima_posicao: "FINALIZACAO",
          slug: "CANCELADO",
          time_line: "5"
        }
      }
    },

    components: {
      Notificacoes
    },

    props: {
      dialog: Boolean,
      consulta: Object
    },

    computed: {
      novaDataPrevista () {
        return this.consulta.item.data_prevista ? new Date(new Date(this.consulta.item.data_prevista).getUTCFullYear(), new Date(this.consulta.item.data_prevista).getUTCMonth(), new Date(this.consulta.item.data_prevista).getUTCDate()) : null
      }
    },

    methods: {
      notificar (val) {
        this.$swal({
          title: 'Confirmação',
          text: `Deseja realmente notificar ${val.msg} do objeto ${this.consulta.item.numero_pedido}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
        }).then((res) => {
          if(res.isConfirmed) {
            let url = `${baseApiUrl}/notifyChannel/${this.codigo_empresa}/${this.consulta.item.numero_rastreio}`
            let data = moment(new Date()).format("YYYY-MM-DD")
            let data_hora = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            
            axios.get(url, {
              params: {
                info: [
                  {
                    name: "Shopee",
                    info_line: {
                      status: val.status,
                      cod_situacao: val.cod_situacao,
                      data_entrega: data,
                      data_ultima_posicao: data_hora,
                      ultima_posicao: val.ultima_posicao,
                      slug: val.slug,
                      time_line: val.time_line
                    }
                  }
                ]
              }
            }).then(() => {
              showSuccess(`Notificação d${val.msg} efetuada com sucesso!`)
            }).catch(() => {
              showError("Erro na notificação do objeto")
            })
          }
        })
      }
    },

    mounted() {
      this.codigo_empresa = Number(this.$store.state.cod_empresa)
    }

  }
</script>